import React from "react"
import Loadable from "react-loadable"
import "styles/global.scss"


const loader = () => <div>Loading.</div>

const Container = Loadable({
    loader: () => import("containers/Chapter7"),
    loading: loader,
  })

const Chapter7 = () => {
    return (
        <Container />
    )
}

export default Chapter7;